import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode
}

const Button = ({ children, ...props }: Props) => {
  return (
    <button
      className="bg-teal-600 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded mt-3 block mx-auto"
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
