import React from 'react'

interface Props {
  id?: string
  name: string
  label: string
  type?: React.InputHTMLAttributes<HTMLInputElement>['type']
  placeholder?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  required?: boolean
}

const TextInput = ({
  id,
  label,
  name,
  type = 'text',
  placeholder = '',
  value,
  onChange,
  error,
  required = false,
}: Props) => {
  const conditionalClassNames = error
    ? 'border-red-500 mb-3'
    : 'border-gray-200 focus:border-gray-500'

  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700  text-xs font-bold mb-2"
        htmlFor={id || label}
      >
        {label}
      </label>
      <input
        className={
          'appearance-none block w-full bg-white text-gray-700 bg-gray-200 focus:bg-white border rounded py-3 px-4 leading-tight focus:outline-none  ' +
          conditionalClassNames
        }
        id={id || label}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </>
  )
}

export default TextInput
