import React from 'react'

const encodeNetlifyFormData = (data: { [inputName: string]: any }) =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')

export const submitNetlifyForm = (formData = {}, formName: string) =>
  fetch('/netlify', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encodeNetlifyFormData({
      'form-name': formName,
      'bot-field': (document.getElementById('bot-field') as HTMLInputElement)
        .value,
      ...formData,
    }),
  })

const NetlifyFormWrapper = ({
  formName,
  onSubmit,
  children,
  ...props
}: Props) => {
  return (
    <form
      name={formName}
      onSubmit={onSubmit}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      {...props}
    >
      <input type="hidden" name="bot-field" id="bot-field" />
      <input type="hidden" name="form-name" value={formName} />
      {children}
    </form>
  )
}

interface Props {
  formName: string
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  children: React.ReactNode
  className?: string
}

export default NetlifyFormWrapper
