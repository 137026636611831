import React from 'react'

interface Props {
  type: 'success' | 'error'
  children: React.ReactNode
}

const Alert = ({ type, children }: Props) => {
  const conditionalClassNames =
    type === 'error'
      ? 'bg-red-100 border-red-400 text-red-700'
      : 'bg-green-100 border-green-400 text-green-700'
  return (
    <div
      className={`border border-red-400 text-red-700 px-4 py-3 mt-2 text-center rounded relative ${conditionalClassNames}`}
      role="alert"
    >
      {children}
    </div>
  )
}

export default Alert
