import React, { useState } from 'react'

import NetlifyFormWrapper, {
  submitNetlifyForm,
} from '../components/Forms/NetlifyFormWrapper'
import TextInput from '../components/Forms/TextInput'
import Textarea from '../components/Forms/Textarea'
import Button from '../components/Button'
import Alert from '../components/Alert'
import { SEODefaults } from '../components/SEO'

import useInputValue from '../hooks/useInputValue'

import isEmail from '../utils/validation/isEmail'

const GATSBY_DOMAIN_NAME = process.env.GATSBY_DOMAIN_NAME
const GATSBY_DOMAIN_OFFER_PLACEHOLDER =
  process.env.GATSBY_DOMAIN_OFFER_PLACEHOLDER

const formName = 'Domain Offer'

interface FormValidationErrors {
  firstName?: string
  lastName?: string
  email?: string
  offerAmountUsd?: string
  message?: string
}

const validateForm = (
  firstName: string,
  lastName: string,
  email: string,
  offerAmountUsd: string,
) => {
  const errors: FormValidationErrors = {}

  if (firstName.length < 1) errors.firstName = 'Required'
  if (lastName.length < 1) errors.lastName = 'Required'
  if (offerAmountUsd.length < 1) errors.offerAmountUsd = 'Required'

  if (email.length < 1) errors.email = 'Required'
  if (!isEmail(email)) errors.email = 'Please enter a valid email'

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  }
}

const HomePage = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState(
    {} as FormValidationErrors,
  )

  const firstName = useInputValue()
  const lastName = useInputValue()
  const email = useInputValue()
  const offerAmountUsd = useInputValue()
  const message = useInputValue()

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setValidationErrors({})
    setIsLoading(true)
    setIsSuccess(false)

    try {
      const { isValid, errors } = validateForm(
        firstName.value,
        lastName.value,
        email.value,
        offerAmountUsd.value,
      )

      if (!isValid) {
        setValidationErrors(errors)
        return
      }

      const formData = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        offerAmountUsd: offerAmountUsd.value,
        message: message.value,
      }

      await submitNetlifyForm(formData, formName)
      setIsSuccess(true)
    } catch (err) {
      console.error(err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEODefaults />
      <main className="bg-gray-300 min-h-screen wrapper">
        <div className="container flex">
          <div className="max-w-screen-md min-h-screen w-full mx-auto h-full flex items-center justify-center">
            <div>
              <NetlifyFormWrapper
                formName={formName}
                onSubmit={onSubmit}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              >
                <h1 className="text-2xl mb-5 text-center">
                  The domain{' '}
                  <strong className="text-teal-600">
                    {GATSBY_DOMAIN_NAME}
                  </strong>{' '}
                  is for sale
                </h1>
                <div className="flex">
                  <div className="w-full md:w-1/2 px-3 mb-3">
                    <TextInput
                      label="First name"
                      required
                      name="firstName"
                      {...firstName}
                      error={validationErrors.firstName}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-3">
                    <TextInput
                      label="Last name"
                      name="lastName"
                      required
                      {...lastName}
                      error={validationErrors.lastName}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full px-3 mb-3">
                    <TextInput
                      label="Email"
                      type="email"
                      name="email"
                      required
                      {...email}
                      error={validationErrors.lastName}
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="w-full px-3 mb-3">
                    <TextInput
                      label="Offer in USD"
                      placeholder={GATSBY_DOMAIN_OFFER_PLACEHOLDER}
                      type="number"
                      name="offerAmountUsd"
                      required
                      {...offerAmountUsd}
                      error={validationErrors.offerAmountUsd}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="w-full px-3 mb-3">
                    <Textarea
                      label="Message (Optional)"
                      name="message"
                      {...message}
                    />
                  </div>
                </div>
                {}
                {!isSuccess && <Button type="submit">Make offer</Button>}
                {isError && (
                  <Alert type="error">
                    Something went wrong! Please try again later.
                  </Alert>
                )}
                {isSuccess && (
                  <Alert type="success">We will contact you shortly.</Alert>
                )}
              </NetlifyFormWrapper>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default HomePage
