import React from 'react'

interface Props {
  id?: string
  label: string
  name: string
  placeholder?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  error?: string
  rows?: number
}

const Textarea = ({
  id,
  label,
  name,
  placeholder = '',
  value,
  onChange,
  error,
  rows = 5,
}: Props) => {
  const conditionalClassNames = error
    ? 'border-red-500 mb-3'
    : 'border-gray-200 focus:border-gray-500'

  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={id || label}
      >
        {label}
      </label>
      <textarea
        className={
          'appearance-none block w-full bg-gray-200 focus:bg-white text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none  ' +
          conditionalClassNames
        }
        name={name}
        id={id || label}
        value={value}
        onChange={onChange}
        rows={rows}
        placeholder={placeholder}
      ></textarea>
    </>
  )
}

export default Textarea
