import { useState } from 'react'

const defaultFormatter = (value: string) => value

const useInputValue = (initialValue = '', formatter = defaultFormatter) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e: React.ChangeEvent<any>) =>
    setValue(formatter(e.target.value))

  return {
    value,
    onChange,
  }
}

export default useInputValue
